import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/blog.css"

const WikiPost = ({ data, location }) => {
  const post = data.markdownRemark
  const img = post.frontmatter.image.childImageSharp.fluid

  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="blog">
        <div className="container">
          <div className="blog-block blog-block_padding">
            <div className="blog-container">
              <div className="blog-navigation-title">
                <Link to="/" className="blog-navigation-title_color">
                  Главная /{" "}
                </Link>
                <Link to="/wiki" className="blog-navigation-title_color">
                  Wiki /{" "}
                </Link>
                <span className="blog-navigation-title_color">
                  {post.frontmatter.title}
                </span>
              </div>
            </div>
            <div className="blog-container-descriptions">
              <Img className="blog-container-img" fluid={img} alt="" />
            </div>
            <div className="blog-container">
              <div className="blog-elements">
                <div className="blog-elements__left">
                  <span className="blog-elements__item blog-elements__date">
                    {post.frontmatter.date}
                  </span>
                </div>
              </div>
            </div>
            <div className="blog-container blog-container-descriptions">
              <div className="blog-text">
                <h2 className="blog-container-descriptions__mainhead">
                  {post.frontmatter.title}
                </h2>
                <div className="blog-container-descriptions__line">&nbsp;</div>
              </div>
            </div>
            <div className="blog-container blog-container-descriptions">
              <div
                className="blog-text"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WikiPost

export const pageQuery = graphql`
  query WikiPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
